<template>
  <div class="add-facility mb-3 pb-3">
    <h3 class="text-primary main-title">Add Facility</h3>
    <ValidationObserver ref="addFacility" v-slot="{ handleSubmit }">
      <form id="addFacility" @submit.prevent="handleSubmit(onSubmit)">
        <CCard class="mt-2">
          <CCardBody>
            <CRow>
              <CCol lg="6">
                <CRow name="facility_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Facility Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="name"
                        :value="facility.name"
                        @input="handleInput"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Category</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation_type_id"
                        :value="facility.organisation_type_id"
                        :options="
                          options && options['organization_type']
                            ? options['organization_type']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Is this a Group Facility</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="group"
                        :value="facility.group"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Type</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="type"
                        :value="facility.type"
                        :options="
                          options && options['type'] ? options['type'] : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3" name="country">
                  <label class="required col-lg-12 col-md-12">Country</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="country_ids"
                        :value="facility.country_ids"
                        :options="
                          options && options['country']
                            ? options['country']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="location" class="required col-lg-12 col-md-12"
                    >City</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="location_ids"
                        :value="facility.location_ids"
                        :options="
                          options && options['location']
                            ? options['location']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="district" class="mb-3">
                  <label class="col-lg-12 col-md-12">Regions</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="district_ids"
                      :value="facility.district_ids"
                      :options="
                        options && options['districts']
                          ? options['districts']
                          : []
                      "
                      :multiple="false"
                      :clearable="false"
                      @input="handleChangeSelect"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="license_authority" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >License Authority</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="issue_auth_ids"
                        :value="facility.issue_auth_ids"
                        :options="
                          options && options['license_authority']
                            ? options['license_authority']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <h5 class="pb-2 text-primary">Main Contact</h5>
            <CRow>
              <CCol lg="6">
                <CRow name="first_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">First Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_name"
                        :value="facility.first_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="last_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Last Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="last_name"
                        :value="facility.last_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="phone" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="facility.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        :error="errors[0]"
                        @change="handleInput"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="email" class="mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Email
                    <em
                      class="fas fa-circle-info superscript-info-icon"
                      v-c-tooltip="'You may change this email id if required!'"
                    >
                    </em>
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="facility.email"
                        :error="errors[0]"
                        @input="handleInput"
                        autocomplete="off"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{
                        required: true,
                        password_length: 8,
                        password_strength:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="facility.password"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="new-password"
                      />
                      <small class="hint">
                        Password format: 8 characters - containing upper & lower
                        case letters, numbers and a special character. No words.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="confirm_password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Confirm Password</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="confirmPassword"
                        :value="facility.confirmPassword"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="confirm-password"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <div class="text-center mx-0 mt-2 pt-4 action-area">
          <CButton
            variant="outline"
            size="lg"
            type="button"
            color="primary"
            shape="square"
            class="px-5 m-1 mt-3"
            @click="CancelForm()"
            >Cancel</CButton
          >
          <CButton
            variant="outline"
            size="lg"
            type="button"
            color="primary"
            shape="square"
            class="px-5 m-1 mt-3"
            @click="resetForm"
            >Clear All</CButton
          >
          <CButton
            type="submit"
            size="lg"
            color="primary"
            shape="square"
            class="px-5 m-1 mt-3"
            >Submit</CButton
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Vue from "vue";
import m from "moment";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import router from "@/router/index";
import { getLocalOrgDetail } from "@/helpers/helper";

import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("email", { ...email, message: "Invalid email" });
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});

export default {
  components: {
    Select,
    TextInput,
    RadioButton,
    PhoneInput,
    PasswordInput,
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "getOrganisationTypeList",
      "licenseIssuedByCountrys",
      "getLocationByCountrysID",
      "getDistrictsByLocationsID",
      "getDefaultFacilityRegisterUser",
    ]),
    options() {
      return {
        country: this.customCountry || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        location: this.customLocation || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        organization_type: this.getOrganisationTypeList || [],
        license_authority: this.license_authority || [],
        districts: this.customDistricts || [],
        type: [
          { id: 1, label: "Government" },
          { id: 2, label: "Private" },
          { id: 3, label: "Both" },
        ],
      };
    },
    customCountry() {
      return Array.isArray(this.payload["country_ids"])
        ? this.locationCountryInfo.filter(
            ({ code }) => !this.payload["country_ids"].includes(code)
          )
        : this.locationCountryInfo;
    },
    customLocation() {
      let opt_location =
        this.getLocationByCountrysID({
          country_id: this.payload?.country_ids,
        }) || [];
      return Array.isArray(this.payload["location_ids"])
        ? opt_location.filter(
            ({ code }) => !this.payload["location_ids"].includes(code)
          )
        : opt_location;
    },
    customDistricts() {
      let opt_district =
        this.getDistrictsByLocationsID({
          location_id: this.payload?.location_ids,
        }) || [];
      let data = Array.isArray(this.payload["district_ids"])
        ? opt_district.filter(
            ({ code }) => !this.payload["district_ids"].includes(code)
          )
        : opt_district;
      return data?.sort((a, b) => a.label.localeCompare(b.label));
    },
    license_authority() {
      let opt_license_auth =
        this.licenseIssuedByCountrys({
          country_id: this.payload?.country_ids,
        }) || [];
      return this.payload["issue_auth_ids"] &&
        Array.isArray(this.payload["issue_auth_ids"])
        ? opt_license_auth.filter(
            ({ code }) => !this.payload["issue_auth_ids"].includes(code)
          )
        : opt_license_auth;
    },
  },
  data() {
    return {
      facility: {},
      payload: {},
      confirmPassword: null,
    };
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "fetchOrganisationTypeList",
      "fetchFacilityLocations",
      "fetchCredentialDetails",
      "showToast",
      "fetchAutoGenPassword",
      "initOrganisationFetchOptions",
    ]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.facility, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.facility, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "country_ids" && value?.code === 181) {
        this.handleChangeSelect("location_ids", {
          code: 1,
          label: "Abu Dhabi",
          country_id: 181,
        });
      }
    },
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.facility, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.facility, name, value);
      const facilityType = {
        type: "type",
      };
      if (facilityType[name]) {
        this.payload["private"] = val === 2 || val === 3;
        this.payload["goverment"] = val === 1 || val === 3;
      } else {
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      }
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    resetForm() {
      this.facility = {};
      this.payload = {};
      this.fetchAutoGenPassword();
      this.facility = {
        email: this.getDefaultFacilityRegisterUser?.email,
        name: this.getDefaultFacilityRegisterUser?.org_name,
        unique_id: this.getDefaultFacilityRegisterUser?.unique_id,
      };
    },
    CancelForm() {
      this.$router.push({
        path: getLocalOrgDetail()?.path_acronym
          ? `/${getLocalOrgDetail()?.path_acronym}`
          : `/`,
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.addFacility.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      //this.payload.password = this.autoGenPassword
      // ? this.autoGenPassword
      //: null
      let finalPayload = {
        ...this.payload,
        country_ids: this.payload.country_ids ? [this.payload.country_ids] : [],
        location_ids: this.payload.location_ids
          ? [this.payload.location_ids]
          : [],
        district_ids: this.payload.district_ids
          ? [this.payload.district_ids]
          : [],
        description: this.facility.name,
        issue_auth_ids: this.payload?.issue_auth_ids
          ? [this.payload.issue_auth_ids]
          : [],
        email: this.payload?.email || this.facility.email,
        name: this.facility.name,
        unique_id: this.facility.unique_id,
      };
      this.$emit("submit", finalPayload);
    },
    checkDepententSelect({ changedField, depententField, depentent }) {
      this.payload[changedField] &&
      this.facility[depententField] &&
      this.payload[changedField] === this.facility[depententField][depentent]
        ? null
        : this.handleChangeSelect(depententField, null);

      return;
    },
  },
  mounted() {
    this.scrollTop();
    this.initOrganisationFetchOptions().then((res) => {
      this.handleChangeSelect("country_ids", {
        code: 181,
        label: "United Arab Emirates",
      });
      this.handleChangeSelect("issue_auth_ids", {
        code: 7,
        label: "Department of Health",
      });
    });
    if (
      this.getDefaultFacilityRegisterUser.email == "" ||
      this.getDefaultFacilityRegisterUser.email == undefined
    ) {
      router.push("/check-facility");
    } else {
      this.facility = {
        email: this.getDefaultFacilityRegisterUser?.email,
        name: this.getDefaultFacilityRegisterUser?.org_name,
        unique_id: this.getDefaultFacilityRegisterUser?.unique_id,
      };
    }
  },
  watch: {
    "facility.country_ids"() {
      this.checkDepententSelect({
        changedField: "country_ids",
        depententField: "location_ids",
        depentent: "country_id",
      });
    },
    "facility.location_ids"() {
      this.checkDepententSelect({
        changedField: "location_ids",
        depententField: "district_ids",
        depentent: "location_id",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}
.sm-title {
  font-size: 0.8rem;
}

.superscript-info-icon {
  vertical-align: super;
  font-size: 0.8em;
}
</style>
