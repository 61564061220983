<template>
  <div class="container-md col-lg-8 col-md-8 mb-3">
    <!-- <CheckFacility :customer="2" @submit="CheckFacility" /> -->
    <div v-if="isfacilityadded == false">
      <AddFacility :customer="2" @submit="createFacility" />
    </div>
    <div v-else>
      <div class="add-facility mb-3 pb-3">
        <h3 class="text-primary main-title">Add Facility</h3>
      </div>
      <CCard class="mt-2">
        <CCardBody class="text-center">
          <CCol lg="12">
            <CRow name="email" class="mb-3">
              <label class="col-lg-4 col-md-4">Email Id</label>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <TextInput name="email" :value="email" :disabled="true" />
              </div>
            </CRow>
          </CCol>
          <div lg="12">
            Thanks for you registration please click here to
            <a class="cursor-pointer link-c" v-on:click="gotoSignIn()">
              Sign In
            </a>
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>
<script>
import AddFacility from "@/containers/Facility/AddFacility";
//import CheckFacility from "@/containers/Facility/CheckFacilityUser"
import { mapActions } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
export default {
  components: {
    AddFacility,
    TextInput,
    //CheckFacility
  },
  data() {
    return {
      isfacilityadded: false,
      email: "",
    };
  },
  name: "FacilityAdd",
  methods: {
    ...mapActions(["addFacility"]),
    createFacility(payload) {
      this.addFacility({ customer_uid: 2, payload }).then((res) => {
        this.email = res.data.original_org_id;
        this.isfacilityadded = true;
        this.$store.commit("SET_FACILITY_DETAILS", {});
      });
    },
    gotoSignIn() {
      this.$router.push({
        path: "/dohae",
      });
    },
  },
};
</script>
<style scoped>
.link-c {
  color: #085d66e6;
}
</style>
